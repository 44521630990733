$(document).ready(() => {
  // Animates to the selected div
  $('.sidebar-menu').on('click', () => {
    let href = $(this).find('a').attr('href');
    setTimeout(() => {
      $("html, body").stop().animate({
        scrollTop: $(href).offset().top
      }, 500)
    }, 100)
  })

  // Calls modal for contact
  $('.modal').modal()
})
